import React, { useState } from "react";
import { Button, Modal, Radio, Drawer, notification } from "antd";
import type { RadioChangeEvent } from "antd";
import { getSecretKey, getLoginStorage, getSIGN } from "../LocalStorage/Locals";
import SpinnerComponent from "./SpinnerComponent";
import "../styles/Spinner.css";
import "../styles/ButtonStyle.css";
import Buy from "../Images/Buy.svg";
import { getIP } from "../api";

type CardInfoProps = {
  code?: string;
  name?: string;
  rec?: string;
};

const CardBuy: React.FC<CardInfoProps> = ({ code, name, rec }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const onClose = () => {
    setDrawerOpen(false);
  };
  const [url, setUrl] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [PaymentType, setPaymentType] = useState("qr");
  const login = getLoginStorage();
  const secretKey = getSecretKey();
  const SIGN = getSIGN();
  const fetchItemData = async () => {
    setIsLoading(true);
    const ip = await getIP();

    const response = await fetch(
      "https://plg.kras.ru/PlgApi.ashx?CMD=CARD_SALE&login=" +
        login +
        "&KEY=" +
        secretKey +
        "&SIGN=" +
        SIGN +
        "&code=" +
        code +
        "&payment_type=" +
        PaymentType +
        `&APP_VERSION=SNV.Web.v.1.7${rec || ""}` +
        `&IP=${ip || ""}`
    );
    const data = await response.json();
    if (data.error === null) {
      window.open(data.url, "_blank");
      setUrl(data.url);
      setDrawerOpen(true);
      setIsModalOpen(false);
    } else if (data.error) {
      console.log(data.error);
      api.open({
        message: "Ошибка",
        description: data.error,
      });
    }
    // setUrl(data.url);
    setIsLoading(false);
    // window.open(data.url, "_blank");
    // setDrawerOpen(true);
    // setIsModalOpen(false);
  };

  return (
    <>
      {contextHolder}
      <Button className="green-button" onClick={() => setIsModalOpen(true)}>
        <img src={Buy} />
        <p>Купить</p>
      </Button>
      {isLoading && <SpinnerComponent />}
      <Modal
        title="Выберите способ оплаты "
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalOpen(false)}>
            Отмена
          </Button>,
          <Button key="ok" className="blue-button" onClick={fetchItemData}>
            Купить
          </Button>,
        ]}
      >
        <Radio.Group
          onChange={(e: RadioChangeEvent) => setPaymentType(e.target.value)}
          value={PaymentType}
        >
          <Radio value={"qr"}>СБП</Radio>
          <Radio value={"card"}>Карта</Radio>
        </Radio.Group>
        <p>Карта для покупки - {name}</p>
      </Modal>
      <Drawer
        title="Оплата"
        placement="top"
        onClose={onClose}
        open={drawerOpen}
        height={200}
      >
        <p>
          Если автоматический переход на страницу оплаты не произошел - нажмите
          на{" "}
          <a href={url} target="_blank">
            ссылку
          </a>
        </p>
      </Drawer>
    </>
  );
};

export default CardBuy;
