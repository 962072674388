import React, { useState } from "react";
import { Button, Modal, Radio, Drawer } from "antd";
import type { RadioChangeEvent } from "antd";
import {
  getSecretKey,
  getLoginStorage,
  getSIGN,
} from "../../LocalStorage/Locals";
import SpinnerComponent from "../SpinnerComponent";
import "../../styles/Spinner.css";
import "../../styles/PromotionStyle.css";
import "../../styles/ButtonStyle.css";
import Buy from "../../Images/Buy.svg";
import { getIP } from "../../api";
type CardInfoProps = {
  code?: string;
  name?: string;
  units?: number;
};
type ActionData = {
  id: number;
  name: string;
  price: number;
  helpLink?: string;
};
const CardBuyAction: React.FC<CardInfoProps> = ({ code, name, units }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const onClose = () => {
    setDrawerOpen(false);
  };
  const [Items, setItems] = useState<ActionData[]>([]);
  const [url, setUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [PaymentType, setPaymentType] = useState("qr");
  const login = getLoginStorage();
  const secretKey = getSecretKey();
  const SIGN = getSIGN();
  const fetchItemData = async () => {
    setIsLoading(true);
    const ip = await getIP();
    const response = await fetch(
      "https://plg.kras.ru/PlgApi.ashx?CMD=CARD_SALE&login=" +
        login +
        "&KEY=" +
        secretKey +
        "&SIGN=" +
        SIGN +
        "&code=" +
        code +
        "&payment_type=" +
        PaymentType +
        "&APP_VERSION=SNV.Web.v.1.7" +
        `&IP=${ip || ""}`
    );
    const data = await response.json();
    setIsLoading(false);
    window.open(data.url, "_blank");
    setUrl(data.url);
    setDrawerOpen(true);
    setIsModalOpen(false);
  };
  const fetchItemDataBuyAction = async (ActionCode: number) => {
    setIsLoading(true);

    const ip = await getIP();
    console.log(`CardBuyAction::fetchItemDataBuyAction | fetched ip: ${ip}`);

    const response = await fetch(
      "https://plg.kras.ru/PlgApi.ashx?CMD=CARD_SALE&login=" +
        login +
        "&KEY=" +
        secretKey +
        "&SIGN=" +
        SIGN +
        "&code=CDSET_" +
        ActionCode +
        "&payment_type=" +
        PaymentType +
        "&APP_VERSION=SNV.Web.v.1.7" +
        `&IP=${ip || ""}`
    );
    const data = await response.json();
    setUrl(data.url);
    setIsLoading(false);
    window.open(data.url, "_blank");
    setDrawerOpen(true);
    setIsModalOpen(false);
  };
  const fetchItemDataAction = async () => {
    setIsLoading(true);

    const ip = await getIP();
    console.log(`CardBuyAction::fetchItemDataAction | fetched ip: ${ip}`);

    const response = await fetch(
      "https://plg.kras.ru/PlgApi.ashx?CMD=CARD_SETS&login=" +
        login +
        "&KEY=" +
        secretKey +
        "&SIGN=" +
        SIGN +
        "&CODE=" +
        code +
        "&IP=" +
        ip
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setItems(data.cdsets);
        console.log(Items);
      });

    setIsLoading(false);
  };

  return (
    <>
      <Button
        className="green-button"
        onClick={() => {
          setIsModalOpen(true);
          fetchItemDataAction();
        }}
      >
        <img src={Buy} alt="Иконка покупки" />
        <p>Купить</p>
      </Button>
      {isLoading && <SpinnerComponent />}
      <Modal
        title="Выберите способ оплаты"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalOpen(false)}>
            Отмена
          </Button>,
        ]}
      >
        <Radio.Group
          onChange={(e: RadioChangeEvent) => setPaymentType(e.target.value)}
          value={PaymentType}
        >
          <Radio value={"qr"}>СБП</Radio>
          <Radio value={"card"}>Карта</Radio>
        </Radio.Group>
        <p className="header-txt">Доступные акционные наборы</p>
        <div className="promotion-buy-modal">
          <p>
            {name} - {units} Руб.
          </p>
          <Button className="blue-button" onClick={fetchItemData}>
            Купить
          </Button>
        </div>
        {Items.map((item) => (
          <div key={item.id} className="promotion-buy-modal">
            <p>
              {item.name} - {item.price} Руб.
              <br></br>
              {item.helpLink && (
                <a href={item.helpLink} target="_blank">
                  {" "}
                  Подробнее
                </a>
              )}
            </p>
            <Button
              className="blue-button"
              onClick={() => fetchItemDataBuyAction(Math.floor(item.id))}
            >
              Купить
            </Button>
          </div>
        ))}
        <p>{}</p>
      </Modal>
      <Drawer
        title="Оплата"
        placement="top"
        onClose={onClose}
        open={drawerOpen}
        height={200}
      >
        <p>
          Если автоматический переход на страницу оплаты не произошел - нажмите
          на{" "}
          <a href={url} target="_blank">
            ссылку
          </a>
        </p>
      </Drawer>
    </>
  );
};

export default CardBuyAction;
