import React, { useState, useEffect } from "react";
import { Button, Modal, Input, notification, Radio, Drawer } from "antd";
import { getSecretKey, getLoginStorage, getSIGN } from "../LocalStorage/Locals";
import SpinnerComponent from "./SpinnerComponent";
import "../styles/Spinner.css";
import "../App.css";
import "../styles/ButtonStyle.css";
import type { RadioChangeEvent } from "antd";
import Buy from "../Images/Buy.svg";
type CardInfoProps = {
  cardNo?: string;
  nominal: number;
};

const CardPay: React.FC<CardInfoProps> = ({ cardNo, nominal }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const onClose = () => {
    setDrawerOpen(false);
  };
  const [url, setUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [PaymentType, setPaymentType] = useState("qr");
  const onChange = (e: RadioChangeEvent) => {
    setPaymentType(e.target.value);
  };
  const login = getLoginStorage();
  const [api, contextHolder] = notification.useNotification();
  const [sum, setSum] = useState(String(nominal));
  const secretKey = getSecretKey();
  const SIGN = getSIGN();
  const fetchresponse = async () => {
    setIsLoading(true);
    const response = await fetch(
      "https://plg.kras.ru/PlgApi.ashx?CMD=CARD_PAY&login=" +
        login +
        "&KEY=" +
        secretKey +
        "&SIGN=" +
        SIGN +
        "&sum=" +
        sum +
        "&cardNo=" +
        cardNo +
        "&payment_type=" +
        PaymentType +
        "&APP_VERSION=SNV.Web.v.1.7"
    );
    const data = await response.json();
    if (data.error === null) {
      window.open(data.url, "_blank");
      setUrl(data.url);
      setDrawerOpen(true);
      setIsModalOpen(false);
    } else if (data.error) {
      console.log(data.error);
      api.open({
        message: "Ошибка",
        description: data.error,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // console.log(PaymentType);
  }, [PaymentType]);

  return (
    <>
      {contextHolder}
      <Button
        className="green-button-extra"
        onClick={() => setIsModalOpen(true)}
      >
        <img src={Buy} />
        <p>Пополнить</p>
      </Button>
      {isLoading && <SpinnerComponent />}
      <Modal
        title="Введите сумму пополнения карты"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setIsModalOpen(false)}>
            Отмена
          </Button>,
          <Button key="ok" className="blue-button" onClick={fetchresponse}>
            Пополнить
          </Button>,
        ]}
      >
        <Input
          className="custom-input"
          type="number"
          defaultValue={nominal}
          value={sum}
          onChange={(e) => setSum(e.target.value)}
        />
        <p>Метод оплаты</p>
        <Radio.Group onChange={onChange} value={PaymentType}>
          <Radio value={"qr"}>СБП</Radio>
          <Radio value={"card"}>Карта</Radio>
        </Radio.Group>
      </Modal>
      <Drawer
        title="Оплата"
        placement="top"
        onClose={onClose}
        open={drawerOpen}
        height={200}
      >
        <p>
          Если автоматический переход на страницу оплаты не произошел - нажмите
          на{" "}
          <a href={url} target="_blank">
            ссылку
          </a>
        </p>
      </Drawer>
    </>
  );
};

export default CardPay;
