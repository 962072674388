const Locals = () => {
  return <></>;
};
export const getSession = () => {
  return JSON.parse(localStorage.getItem("session") || "false");
};
export const setSessionInLocalStorage = (token: string) => {
  localStorage.setItem("session", JSON.stringify(token));
  return true;
};

export const setSecretKey = (value: string) => {
  localStorage.setItem("SecretKey", value);
  return true;
};
export const getSecretKey = () => {
  return localStorage.getItem("SecretKey");
};

export const setLoginStorage = (token: string) => {
  localStorage.setItem("Login", token);
  return true;
};
export const getLoginStorage = () => {
  return localStorage.getItem("Login");
};
export const setSIGN = (token: string) => {
  localStorage.setItem("Sign", token);
  return true;
};
export const getSIGN = () => {
  return localStorage.getItem("Sign");
};
export const setFormattedLogin = (token: string) => {
  localStorage.setItem("FormattedLogin", token);
  return true;
};
export const getFormattedLogin = () => {
  return localStorage.getItem("FormattedLogin");
};
export const setPass = (token: string) => {
  localStorage.setItem("Pass", token);
  return true;
};
export const getPass = () => {
  return localStorage.getItem("Pass");
};
export default Locals;
