import {
  getLoginStorage,
  setFormattedLogin,
  getSecretKey,
  getSIGN,
} from "../LocalStorage/Locals";
import SpinnerComponent from "./SpinnerComponent";
import "../styles/PersonalInfoStyle.css";
import { UserOutlined } from "@ant-design/icons";
import { getIP } from "../api";
import { useState, useEffect } from "react";
const isSafari = (): boolean => {
  const userAgent: string = window.navigator.userAgent;
  const isChrome: boolean = userAgent.includes("Chrome");
  const isSafari: boolean = userAgent.includes("Safari");
  return isSafari && !isChrome;
};
const PersonalInfo: React.FC = () => {
  const login = getLoginStorage();
  const [isLoading, setIsLoading] = useState(false);
  const secretKey = getSecretKey();
  const SIGN = getSIGN();
  const [url, setUrl] = useState("");
  const fetchItemData = async () => {
    setIsLoading(true);
    const response = await fetch(
      "https://plg.kras.ru/PlgApi.ashx?CMD=CABINET_LOGIN&login=" +
        login +
        "&KEY=" +
        secretKey +
        "&SIGN=" +
        SIGN +
        "&APP_VERSION=SNV.Web.v.1.7"
    );
    const data = await response.json();
    // console.log(data);
    setUrl(
      `${data.data.url}?Login=${data.data.Login}&Key=${data.data.Key}&Captcha=${data.data.Captcha}&Sign=${data.data.Sign}`
    );
    // console.log(url);
    setIsLoading(false);
  };
  useEffect(() => {
    if (url && isSafari()) {
      window.location.href = url;
    } else if (url) {
      window.open(url, "_blank");
    }
  }, [url]);
  const normalizePhoneNumber = (phoneNumber: string) => {
    //преобразование номера в +7
    if (phoneNumber.startsWith("9")) {
      return `+7${phoneNumber}`;
    }
    if (phoneNumber.startsWith("8")) {
      return `+7${phoneNumber.slice(1)}`;
    }
    if (phoneNumber.startsWith("7")) {
      return `+${phoneNumber}`;
    }
    if (phoneNumber.startsWith("+7")) {
      return phoneNumber;
    }

    return `+7${phoneNumber}`;
  };
  const normalizedPhoneNumber = normalizePhoneNumber(login as string);
  const formatPhoneNumber = (phoneNumber: string) => {
    const formatted = `+7 (${phoneNumber.slice(2, 5)}) ${phoneNumber.slice(
      5,
      8
    )} ${phoneNumber.slice(8, 10)} ${phoneNumber.slice(10)}`;

    return formatted;
  };

  setFormattedLogin(normalizedPhoneNumber);
  const formattedPhoneNumber = formatPhoneNumber(normalizedPhoneNumber);
  return (
    <>
      {isLoading && <SpinnerComponent />}
      <div className="personal-info-row">
        <p
          className="oldLk-txt"
          onClick={() => {
            fetchItemData();
          }}
        >
          Перейти в старую версию лк
        </p>
        <p className="login-txt">
          <UserOutlined className="user-svg" />
          {formattedPhoneNumber}
        </p>
      </div>
    </>
  );
};

export default PersonalInfo;
